import React, { useState } from 'react'
import styles from './Header.module.scss'
import { routes } from '../../routes/routes'
import MediaQuery from 'react-responsive'
import Link from '../Link/Link'
import BurgerButton from '../BurgerButton/BurgerButton'
import posed from 'react-pose'
import { animations } from './HeaderAnimations'

const NavlinkList = posed.ul(animations.navlinkList)
const Backdrop = posed.div(animations.backdrop)

export default function Header() {
  const [isNarrow, setIsNarrow] = useState(false)
  const [menuIsOpen, setMenuIsOpen] = useState(false)

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen)
  }
  const closeMenu = () => {
    setMenuIsOpen(false)
  }
  return (
    <>
      <MediaQuery minWidth="60em">
        {matches => {
          if (matches) {
            setIsNarrow(false)
            setMenuIsOpen(true)
          } else {
            setIsNarrow(true)
          }
          return null
        }}
      </MediaQuery>
      <div className={styles.headerPlaceholder}></div>
      <header className={styles.header}>
        <nav className={styles.nav}>
          <h1 className={styles.siteTitle}>
            <Link to="/" className={styles.navlink} onClick={closeMenu}>
              MATHIAS DUHAMEL
            </Link>
          </h1>
          {isNarrow ? (
            <BurgerButton clickHandler={toggleMenu} isActive={menuIsOpen} />
          ) : null}
          <NavlinkList
            className={styles.navlinksList}
            pose={isNarrow ? (menuIsOpen ? 'open' : 'closed') : 'static'}
          >
            {Object.keys(routes).map(key => {
              const label = routes[key].label
              const path = routes[key].path

              return (
                <li key={key} className={styles.navlistItem}>
                  <Link
                    to={path}
                    className={styles.navlink}
                    activeClassName={styles.active}
                    partiallyActive={path !== '/' ? true : false}
                    onClick={closeMenu}
                  >
                    {label}
                  </Link>
                </li>
              )
            })}
          </NavlinkList>
        </nav>
      </header>
      {isNarrow ? (
        <Backdrop
          className={styles.backdrop}
          pose={menuIsOpen ? 'open' : 'closed'}
          onClick={closeMenu}
        />
      ) : null}
    </>
  )
}
