export const animations = {
  navlinkList: {
    static: {
      x: '0%',
      applyAtStart: { x: '0%' }
    },
    open: {
      x: '0%'
    },
    closed: {
      x: '100%'
    }
  },
  backdrop: {
    open: {
      opacity: 1,
      applyAtStart: {
        display: 'block'
      }
    },
    closed: {
      opacity: 0,
      applyAtEnd: {
        display: 'none'
      }
    }
  }
}
