import React from 'react'
import styles from './Footer.module.scss'
import Link from '../Link/Link'

export default function Footer() {
  return (
    <footer className={styles.footer}>
      <div className={styles.container}>
        <Link
          to="/follow"
          className={styles.link}
          activeClassName={styles.active}
        >
          Follow
        </Link>
        <Link
          to="/links"
          className={styles.link}
          activeClassName={styles.active}
        >
          Links
        </Link>
        <Link
          to="/privacy"
          className={styles.link}
          activeClassName={styles.active}
        >
          Privacy
        </Link>
      </div>
    </footer>
  )
}
