// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-collection-page-template-jsx": () => import("/opt/build/repo/src/templates/CollectionPageTemplate.jsx" /* webpackChunkName: "component---src-templates-collection-page-template-jsx" */),
  "component---src-pages-404-jsx": () => import("/opt/build/repo/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-artist-jsx": () => import("/opt/build/repo/src/pages/artist.jsx" /* webpackChunkName: "component---src-pages-artist-jsx" */),
  "component---src-pages-contact-jsx": () => import("/opt/build/repo/src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-follow-jsx": () => import("/opt/build/repo/src/pages/follow.jsx" /* webpackChunkName: "component---src-pages-follow-jsx" */),
  "component---src-pages-index-jsx": () => import("/opt/build/repo/src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-links-jsx": () => import("/opt/build/repo/src/pages/links.jsx" /* webpackChunkName: "component---src-pages-links-jsx" */),
  "component---src-pages-live-jsx": () => import("/opt/build/repo/src/pages/live.jsx" /* webpackChunkName: "component---src-pages-live-jsx" */),
  "component---src-pages-privacy-jsx": () => import("/opt/build/repo/src/pages/privacy.jsx" /* webpackChunkName: "component---src-pages-privacy-jsx" */),
  "component---src-pages-upcoming-jsx": () => import("/opt/build/repo/src/pages/upcoming.jsx" /* webpackChunkName: "component---src-pages-upcoming-jsx" */),
  "component---src-pages-works-jsx": () => import("/opt/build/repo/src/pages/works.jsx" /* webpackChunkName: "component---src-pages-works-jsx" */)
}

