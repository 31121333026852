export const routes = {
  index: {
    label: 'Home',
    path: '/'
  },
  live: {
    label: 'Live',
    path: '/live/'
  },
  works: {
    label: 'Works',
    path: '/works/'
  },
  artist: {
    label: 'Artist',
    path: '/artist/'
  },
  upcoming: {
    label: 'Upcoming',
    path: '/upcoming/'
  },
  contact: {
    label: 'Contact',
    path: '/contact/'
  }
}
