import React from 'react'
import styles from './Layout.module.scss'

import Header from '../components/Header/Header'
import Footer from '../components/Footer/Footer'

export default function Layout({ children }) {
  return (
    <div className={styles.layout}>
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  )
}
