import React from 'react'
import { Link as GatsbyLink } from 'gatsby'

function Link({
  children,
  to,
  className,
  activeClassName,
  partiallyActive,
  ...other
}) {
  // test for internal or external link
  const internal = /^\/(?!\/)/.test(to)

  if (internal) {
    return (
      <GatsbyLink
        to={to}
        className={className}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    )
  } else {
    return (
      <a
        className={className}
        href={to}
        target="_blank"
        rel="noopener noreferrer"
        {...other}
      >
        {children}
      </a>
    )
  }
}

export default Link
